import React from "react";
import { NavBar } from "./NavBar";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Layout({ children }) {
  const location = useLocation();
  const pathname = location.pathname.includes("menu");
  return (
    <div className="mx-auto w-100">
      {!pathname && <NavBar />}
      {children}
      <ToastContainer />
    </div>
  );
}

export default Layout;
