import { createSlice } from "@reduxjs/toolkit";

const labelsSlice = createSlice({
  name: "labels",
  initialState: {
    yellow: "",
    purple: "",
    blue: "",
    red: "",
    green: "",
    orange: "",
    black: "",
    sky: "",
    pink: "",
    lime: "",
  },
  reducers: {
    addLabel: (state, action) => {
      const { color, id } = action.payload;      
      state[color] = id;
    },
  },
});

export const { addLabel } = labelsSlice.actions;
export default labelsSlice.reducer;
