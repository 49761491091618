import { getClientOnEzDB } from '../api';
import { getUserInfo } from '../api/trello';
import { login, setAuthChecker, setErrorOnAuthRequest, setIsAuthenticated, setLoading, setPlan } from './authSlice';

export const fetchAndSetUserInfo = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const userInfo = await getUserInfo();
    dispatch(login(userInfo));
  } catch (error) {
    // console.error('Failed to fetch user info:', error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchAndSetAuthStatus = (email) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setAuthChecker(false))
  dispatch(setErrorOnAuthRequest(false));
  
  try {
    const userInfo = await getClientOnEzDB(email);

    if (userInfo) {
      const hasActiveSubscription = userInfo.assinaturasGetViewModels.some(subscription => subscription.ativo);

      if (hasActiveSubscription) {
        dispatch(setIsAuthenticated(true));
        const activeSubscription = userInfo.assinaturasGetViewModels.find(sub => sub.ativo);
        const plan = activeSubscription ? (activeSubscription.periodicidadeId === 1 ? 'monthly' : 'anual') : '';
        dispatch(setPlan(plan));
      } else {
        dispatch(setIsAuthenticated(false));
      }
    } else {
      dispatch(setIsAuthenticated(false));
    }
  } catch (error) {
    dispatch(setIsAuthenticated(false));
    dispatch(setAuthChecker(true))
    dispatch(setErrorOnAuthRequest(true));
  } finally {
    dispatch(setLoading(false)); 
    dispatch(setAuthChecker(true))
  }
};