import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    plan: "",
    userName: null,
    email: null,
    loading: false,
    isAuthChecked: false,
    errorOnAuthRequest: false,
    labels: {
      yellow: "",
      purple: "",
      blue: "",
      red: "",
      green: "",
      orange: "",
      black: "",
      sky: "",
      pink: "",
      lime: "",
    },
  },
  reducers: {
    login: (state, action) => {
      state.userName = action.payload.name;
      state.email = action.payload.email;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setAuthChecker: (state, action) => {
      state.isAuthChecked = action.payload;
    },
    setErrorOnAuthRequest: (state, action) => {
      state.errorOnAuthRequest = action.payload;
    },
  },
});

export const {
  login,
  setIsAuthenticated,
  setLoading,
  setPlan,
  setAuthChecker,
  setErrorOnAuthRequest
} = authSlice.actions;
export default authSlice.reducer;
