import { format, isValid, parse } from "date-fns";

import * as XLSX from "xlsx";

export const objMapping = {
  "Card Name": "name",
  "Card Description": "desc",
  Labels: "idLabels",
  Members: "idMembers",
  "Due Date": "due",
  "Start Date": "start",
  List: "idList",
  "CheckList Name": "CLname",
  "Checklist Item Name": "CLIname",
  "Checklist Item Due Date": "CLIdue",
  "Checklist Item Member": "CLIidMember",
};

export const selectOptions = [
  " ",
  "List",
  "Card Name",
  "Card Description",
  "Labels",
  "Members",
  "Due Date",
  "Start Date",
  "CheckList Name",
  "Checklist Item Name",
  "Checklist Item Due Date",
  "Checklist Item Member",
];

export const validColors = [
  "yellow",
  "purple",
  "blue",
  "red",
  "green",
  "orange",
  "black",
  "sky",
  "pink",
  "lime",
];

export function isValidColor(color) {
  return validColors.includes(color?.toLowerCase());
}

export function isValidDateStart(dateString) {

  const formats = [
    { regex: /^(\d{2})[-/](\d{2})[-/](\d{4})$/, order: [3, 2, 1] },
    { regex: /^(\d{4})[-/](\d{2})[-/](\d{2})$/, order: [1, 2, 3] },
  ];

  for (const format of formats) {
    const match = dateString?.match(format?.regex);
    if (match) {
      const [year, month, day] = format.order.map((i) =>
        parseInt(match[i], 10)
      );
      const date = new Date(year, month - 1, day);

      if (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
        
      ) {        
        return true;
      }
    }
  } 
  return false;
}

export function isValidDateEnd(dateString) {
  const currentDate = new Date();

  const formats = [
    { regex: /^(\d{2})[-/](\d{2})[-/](\d{4})$/, order: [3, 2, 1] },
    { regex: /^(\d{4})[-/](\d{2})[-/](\d{2})$/, order: [1, 2, 3] },
  ];

  for (const format of formats) {
    const match = dateString?.match(format?.regex);
    if (match) {
      const [year, month, day] = format.order.map((i) =>
        parseInt(match[i], 10)
      );
      const date = new Date(year, month - 1, day);

      if (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day &&
        date > currentDate
      ) {
        return true;
      }
    }
  }  
  return false;
}

export function mapColumns(data, trelloMapping, selectOptions) {
  let textColunasPlanilha = "";
  let textColunasTrello = "";

  data.forEach((column) => {
    const columnName = column.column;
    textColunasPlanilha += columnName + "; ";

    if (
      trelloMapping[columnName] &&
      typeof trelloMapping[columnName] === "string"
    ) {
      textColunasTrello += trelloMapping[columnName] + "; ";
    } else {
      textColunasTrello += "null; ";
    }
  });

  return {
    textColunasPlanilha: textColunasPlanilha?.trim()?.toLowerCase(),
    textColunasTrello: textColunasTrello?.trim(),
  };
}

export function transformData(data, objMapping, dataToSend) {
  let transformedDataArray = [];

  const rowCount = data[0].values.length;

  for (let i = 0; i < rowCount; i++) {
    const transformedObject = {};

    data.forEach((column) => {
      const columnName = column.column;
      if (dataToSend[columnName] in objMapping) {
        const mappedKey = objMapping[dataToSend[columnName]];
        const columnValue = column.values[i] ?? null;
        transformedObject[mappedKey] = columnValue;
      }
    });

    transformedDataArray.push(transformedObject);
  }

  const variablesToCheck = ['due', 'start', 'CLdue'];

  variablesToCheck.forEach(variable => {  
    if (
      transformedDataArray.some((c) => c?.[variable]) &&
      transformedDataArray.some((c) => !isValid(c?.[variable]))
    ) {    
      transformedDataArray = transformedDataArray.map((c, idx) => {
        if (isValid(c[variable]) && isValidDateStart(format(c?.[variable], "dd/MM/yyyy"))) {
          return c;
        } else {
          const { [variable]: _, ...rest } = c;
          return rest;
        }
      });
    }
  });  
  return transformedDataArray;
}


export const hasCardNameProperty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === "Card Name") {
      return true;
    }
  }
  return false;
};

export const transformAndSetSelectValues = (columns) => {
  const transformedValues = {};

  const planilhaColumns = columns.textColunasPlanilha.split(";");
  const trelloColumns = columns.textColunasTrello.split(";");

  for (let i = 0; i < planilhaColumns.length; i++) {
    const planilhaColumn = planilhaColumns[i]?.trim();
    const trelloColumn = trelloColumns[i]?.trim();

    if (trelloColumn && trelloColumn !== "null") {
      transformedValues[planilhaColumn] = trelloColumn;
    }
  }
  
  return transformedValues;
};

export const validateDateField = (cardsToCreate, field, validateDate) => {
  return cardsToCreate.some(card => {
    const dateValue = card?.[field];
    if (typeof dateValue !== 'string' || !isValid(parse(dateValue, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date()))) {
      return false;
    }
    return validateDate(format(parse(dateValue, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date()), "dd/MM/yyyy"));
  });
};

export const validateNameField = (cardsToCreate) => {
  if (!cardsToCreate.length) {
    return false;
  }
  return cardsToCreate.every(
    (c) => typeof c.name === "string" && c.name.trim().length > 0
  );
};

export const validateDefaultField = (cardsToCreate, field) => {
  if (!cardsToCreate.length) {
    return false;
  }
  return cardsToCreate.some((c) => c[field]);
};

export const fieldsValidation = (field, cardsToCreate) => {   
  switch (field) {
    case "name":
      return validateNameField(cardsToCreate);
    case "idLabels":      
      return cardsToCreate.every((c)=> isValidColor(c?.idLabels));
    case "due":
      return validateDateField(cardsToCreate, "due", isValidDateEnd);
    case "CLIdue":
      return validateDateField(cardsToCreate, "CLIdue", isValidDateEnd);
    case "start":
      return validateDateField(cardsToCreate, "start", isValidDateStart);
    default:
      return validateDefaultField(cardsToCreate, field);
  }
};

export const saveLayout = async (getUserInfo, createLayoutOnEzDB, layout) => {
  const userInfo = await getUserInfo();

  const layoutData = {
    ...layout,
    email: userInfo.email,
    name: format(new Date(), "dd/MM/yyyy HH:mm:ss"),
  };

  try {
    await createLayoutOnEzDB(layoutData);
  } catch (err) {    
  }
};

export const updateProgress = (createdCards, totalCards, setProgress) => {
  const percentage = Math.round((createdCards / totalCards) * 100);
  setProgress(percentage);
};

export const stopCreation = (isComplete, setIsCancelled, setIsFetching, t) => {
  localStorage.setItem('canceled', 'true');
  setIsCancelled(true);  
  if (isComplete) {
    t.closeModal();
  }
}; 

export const handleSelectFile = (isUnauthorized) => {
  isUnauthorized();
  document.getElementById("file-upload").click();
};

export const handleFileChange = (e, setIsInvalidFile, setData, setFileName, setDataToSend) => {
  setIsInvalidFile(false);
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {
        type: "array",
        cellDates: true,
        dateNF: "dd/mm/yy",
      });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const columns = {};
      if (!jsonData.length) {
        setIsInvalidFile(true);
        return;
      }
      setFileName(file.name);
      jsonData[0].forEach((header, index) => {
        columns[header || `Unnamed_${index + 1}`] = jsonData
          .slice(1)
          .map((row) => row[index]);
      });

      const columnsArray = Object.keys(columns).map((key) => ({
        column: (key || "").toLowerCase(),
        values: columns[key],
      }));

      setData(columnsArray);

      // Create a new object to store the data to send, considering all values
      const newDataToSend = {};
      jsonData.slice(1).forEach((row) => {
        row.forEach((value, index) => {
          const header = jsonData[0][index] || `Unnamed_${index + 1}`;
          if (!newDataToSend[header]) {
            newDataToSend[header] = [];
          }
          newDataToSend[header].push(value);
        });
      });

      setDataToSend(newDataToSend);
    };
    reader.readAsArrayBuffer(file);
  }
};

export const handleSelectChange = (column, value, setDataToSend) => {
  setDataToSend((prevData) => {
    const updatedData = { ...prevData };

    const existingColumn = Object.keys(updatedData).find(
      (key) => updatedData[key] === value
    );

    if (existingColumn) {
      updatedData[existingColumn] = "";
    }

    updatedData[column] = value;

    const checklistItems = [
      "Checklist Item Name",
      "Checklist Item Due Date",
      "Checklist Item Member",
    ];

    if (
      value !== "CheckList Name" &&
      !Object.values(updatedData).includes("CheckList Name")
    ) {
      for (const key in updatedData) {
        if (checklistItems.includes(updatedData[key])) {
          updatedData[key] = "";
        }
      }
    }

    return updatedData;
  });
};

export function capitalizeWords(str) {
  if (!str) {
    return '';
  }
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}