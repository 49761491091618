import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Fade, ListGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import { MdCloudUpload } from "react-icons/md";
import { FaFile } from "react-icons/fa";

import FetchingCardComponent from "../components/FetchingCardComponent";
import ImportFileComponent from "../components/ImportFileComponent";
import {
  capitalizeWords,
  fieldsValidation,
  handleFileChange,
  handleSelectChange,
  handleSelectFile,
  hasCardNameProperty,
  mapColumns,
  objMapping,
  saveLayout,
  selectOptions,
  stopCreation,
  transformAndSetSelectValues,
  transformData,
  updateProgress,
} from "../utils";

import {
  createChecklist,
  createChecklistItem,
  createLabel,
  getMemberId,
  getUserInfo,
  createList, // Adicionei o import de createList aqui
} from "../api/trello";

import CheckComponent from "../components/CheckComponent";
import { createErrorLog, createLayoutOnEzDB, getLayout } from "../api";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAndSetAuthStatus,
  fetchAndSetUserInfo,
} from "../redux/authActions";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

const ImportComponent = () => {
  const { userName, email, isAuthenticated, isAuthChecked } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [dataToSend, setDataToSend] = useState({});
  const [fileName, setFileName] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [cardsToCreate, setCardsToCreate] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [progress, setProgress] = useState(0);
  const [createdCards, setCreatedCards] = useState(0);
  const [isCancelled, setIsCancelled] = useState(false);
  const [layout, setLayout] = useState({});
  const [layoutFetched, setLayoutFetched] = useState(null);
  const [wasLayoutFetched, setWasLayoutFetched] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [isListsFetched, setIsListsFetched] = useState(false);
  const [lists, setLists] = useState(null);

  const totalCards = cardsToCreate.length;

  const handleChooseAnotherFile = () => {
    setData([]);
    setDataToSend({});
    setCardsToCreate([]);
    setLayout({});
    setLayoutFetched(null);
    setWasLayoutFetched(false);
    setFileName("");
  };

  const isUnauthorized = () => {
    if (!isAuthenticated && email && userName && isAuthChecked) {
      navigate("/billing");
      return;
    }
  };

  const handleButtonClick = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  const validateField = (field) => fieldsValidation(field, cardsToCreate);

  useEffect(() => {
    if (!isListsFetched) {
      fetchLists(t);
      setIsListsFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListsFetched]);

  useEffect(() => {
    if (!userName || !email) {
      dispatch(fetchAndSetUserInfo());
    }

    if (email && !isAuthenticated && !isAuthChecked) {
      dispatch(fetchAndSetAuthStatus(email));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, email, isAuthenticated, isAuthChecked]);

  useEffect(() => {
    if (layoutFetched) {
      const initialValues = transformAndSetSelectValues(layoutFetched);
      setDataToSend(initialValues);
    }
  }, [layoutFetched]);

  useEffect(() => {
    if (data.length > 0 && layout.textColunasPlanilha && !wasLayoutFetched) {
      getLayout(layout.textColunasPlanilha?.toLowerCase(), setLayoutFetched);
      setWasLayoutFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, layout]);

  useEffect(() => {
    if (data.length > 0) {
      setCardsToCreate(transformData(data, objMapping, dataToSend));
    }

    setActiveKey(null);
    setLayout(mapColumns(data, dataToSend));
  }, [dataToSend, data]);

  const selectOptionsComponent = useMemo(
    () => (selectedOption) => {
      const isChecklistNameSelected =
        Object.values(dataToSend).includes("CheckList Name");

      const filteredOptions = selectOptions.filter((opt) => {
        if (
          [
            "Checklist Item Name",
            "Checklist Item Due Date",
            "Checklist Item Member",
          ].includes(opt)
        ) {
          return isChecklistNameSelected || opt === selectedOption;
        }
        return true;
      });

      return filteredOptions.map((opt) => <option key={opt}>{opt}</option>);
    },
    [dataToSend]
  );

  const t = window.TrelloPowerUp.iframe({
    appKey: process.env.REACT_APP_TRELLO_KEY,
    appName: "Spread2Board by Ezy PowerUps",
  });

  function GetSecret() {
    return new Promise((resolve, reject) => {
      t.getRestApi()
        .getToken()
        .then(function (secret) {
          let isSetToken = decodeURI(secret).replace('"', "").replace('"', "");
          resolve(isSetToken);
        })
        .catch(function () {
          resolve(null);
        });
    });
  }

  const fetchLists = async (t) => {
    try {
      const secret = await GetSecret(t);
      window.Trello.setToken(secret);

      const board = await t.board("all");
      const boardId = JSON.parse(JSON.stringify(board, ["id"], null, 2)).id;

      let trelloLists = [];

      try {
        trelloLists = await new Promise((resolve, reject) => {
          window.Trello.get(
            `/boards/${boardId}/lists`,
            { fields: "id,name" },
            (lists) => {
              if (lists && lists.length > 0) {
                resolve(lists);
              } else {
                reject("No lists found on the board");
              }
            }
          );
        });
      } catch (error) {
        // Se não houver listas, criar uma lista "To Do"
        //const newList = await createList("To Do", boardId, secret);
        //trelloLists = [newList]; // Usando a nova lista criada
        //t.alert({
        //  message:
        //    "We noticed that your board didn't have any lists, so we automatically created a list named 'To Do' to handle your imports.",
        //});
      }

      const listsObject = trelloLists.reduce((acc, list) => {
        acc[list.name] = list.id;
        return acc;
      }, {});

      setLists(listsObject);
    } catch (error) {
      console.error("Error fetching or creating lists:", error);
      throw error;
    }
  };

  const createCards = async () => {
    isUnauthorized();
    setIsFetching(true);
    localStorage.setItem("canceled", "false");
    setProgress(0);
    setCreatedCards(0);

    try {
      const secret = await GetSecret(t);
      window.Trello.setToken(secret);

      const board = await t.board("all");
      const boardId = JSON.parse(JSON.stringify(board, ["id"], null, 2)).id;

      let _createdCards = 0;
      let dynamicList = { ...lists }; // Copia inicial do objeto lists

      for (const item of cardsToCreate) {
        if (localStorage.getItem("canceled") === "true") {
          throw new Error("Process stopped by user");
        }

        let listId = dynamicList[item.idList];

        // Verifica se a lista existe no dynamicList ou se precisa ser criada
        if (!listId && item.idList) {
          const newList = await createList(item.idList, boardId, secret);
          // busca a lista novamente
          setIsListsFetched(false);
          listId = newList.id;
          dynamicList[item.idList] = newList.id; // Adiciona à dynamicList

          toast(
            `🤖 The list "${item.idList}" was not found and has been created automatically.`
          );
        } else if (!listId) {
          //verifica se To Do já existe
          listId = dynamicList["To Do"];
          if (!listId) {
            // Se nenhuma lista foi encontrada no board, cria a lista "To Do"
            const newList = await createList("To Do", boardId, secret);
            // busca a lista novamente
            setIsListsFetched(false);
            listId = newList.id;
            dynamicList["To Do"] = newList.id;
            toast(
              "🤖 We noticed that your board didn't have any lists, so we automatically created a list named 'To Do' to handle your imports."
            );
          }
        }

        if (!listId) {
          throw new Error("No valid list found to create the card.");
        }

        const cardData = { pos: "top", idList: listId };

        for (const key in item) {
          if (item.hasOwnProperty(key) && key !== "idList" && key !== "name") {
            cardData[key] = item[key];
          } else if (item.hasOwnProperty(key) && key === "name") {
            cardData[key] = item[key];
          }
        }

        const labelPromise = item.idLabels
          ? createLabel(item.idLabels, boardId, secret, t).then((label) => {
              cardData.idLabels = label;
            })
          : Promise.resolve();

        const memberPromise = item.idMembers
          ? getMemberId(item.idMembers, secret).then((memberIds) => {
              cardData.idMembers = memberIds;
            })
          : Promise.resolve();

        await Promise.all([labelPromise, memberPromise]);

        try {
          const card = await window.Trello.post(
            `/cards?key=${process.env.REACT_APP_TRELLO_KEY}`,
            cardData
          );
          _createdCards++;
          setCreatedCards(_createdCards);
          updateProgress(_createdCards, totalCards, setProgress);

          if (item.CLname) {
            const createdChecklist = await createChecklist(
              card.id,
              item.CLname,
              secret
            );
            if (item.CLIname) {
              const checklistItemData = {
                name: item.CLIname,
                idChecklist: createdChecklist.id,
              };

              if (item.CLIdue) {
                checklistItemData.due = item.CLIdue;
              }

              if (item.CLIidMember) {
                checklistItemData.idMembers = [item.CLIidMember];
              }

              await createChecklistItem(
                createdChecklist.id,
                checklistItemData,
                secret
              );
            }
          }
        } catch (error) {
          if (error.status === 429) {
            setTimeout(createCards, 10000);
            return;
          } else {
            const err = {
              message: error?.message,
              tracer: error,
              email,
              url: error?.url || "not found",
            };

            await createErrorLog(err);
            throw error;
          }
        }

        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      saveLayout(getUserInfo, createLayoutOnEzDB, layout);
    } catch (error) {
      setIsFetching(false);
      const err = {
        message: error?.message,
        tracer: error,
        email,
        url: error?.url || "not found",
      };

      await createErrorLog(err);

      t.alert({
        message: error?.message,
      });

      throw error;
    }
  };

  if (!isAuthenticated && !email) {
    return <></>;
  }

  return isFetching ? (
    <FetchingCardComponent
      progress={progress}
      isCancelled={isCancelled}
      createdCards={createdCards}
      totalCards={totalCards}
      stopCreation={() =>
        stopCreation(
          progress === 100 && !isCancelled,
          setIsCancelled,
          setIsFetching,
          t
        )
      }
    />
  ) : (
    <Fade in appear>
      <div className="w-100 d-flex flex-column justify-content-center align-items-center position-relative">
        {data.length === 0 ? (
          <ImportFileComponent
            isAuth={isAuthenticated}
            isAuthChecked={isAuthChecked}
            isInvalidFile={isInvalidFile}
            fileName={fileName}
            handleSelectFile={() => handleSelectFile(isUnauthorized)}
            handleFileChange={(e) =>
              handleFileChange(
                e,
                setIsInvalidFile,
                setData,
                setFileName,
                setDataToSend
              )
            }
          />
        ) : (
          <>
            <h2>Import Data</h2>

            <blockquote className="blockquote text-center">
              <p className="m-0">
                Your import contains{" "}
                <u>
                  <strong>{data[0]?.values?.length}</strong>
                </u>{" "}
                rows
              </p>
              <p>Check your data below and press import when you are ready</p>
            </blockquote>

            <input
              id="file-upload"
              type="file"
              className="d-none"
              accept=".xlsx,.xls,.csv"
              onChange={(e) =>
                handleFileChange(
                  e,
                  setIsInvalidFile,
                  setData,
                  setFileName,
                  setDataToSend
                )
              }
            />
            <Button
              className="d-flex position-absolute"
              onClick={handleChooseAnotherFile}
              style={{
                background: "black",
                border: "none",
                alignItems: "center",
                justifyContent: "center",
                top: 10,
                right: 50,
              }}
            >
              Chose another file
              <FaFile
                style={{
                  marginLeft: "5px",
                  fontSize: "20px",
                }}
              />
            </Button>
            <ListGroup className="w-100 px-4" as="ul">
              <ListGroup.Item
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  borderRadius: "none",
                }}
                className="d-flex w-100"
                as="li"
              >
                <div className="d-flex w-100 align-items-center justify-content-center position-relative">
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      padding: "10px 0",
                    }}
                  >
                    SPREADSHEET COLUMN
                  </span>
                </div>

                <div className="d-flex w-100 align-items-center justify-content-center"></div>
                <div className="d-flex w-100 align-items-center justify-content-center">
                  {" "}
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      padding: "10px 0",
                    }}
                  >
                    TRELLO COLUMN
                  </span>
                </div>
              </ListGroup.Item>
              {data.map((column, index) => {
                const currentField = objMapping[dataToSend[column.column]];
                const validation = validateField(currentField);
                const selectedOption = dataToSend[column.column] || "";
                return (
                  <>
                    <ListGroup.Item
                      key={index}
                      style={{
                        borderRight: "none",
                        borderLeft: "none",
                        borderRadius: "none",
                      }}
                      className="d-flex w-100"
                      as="li"
                    >
                      <div className="d-flex w-100 align-items-center justify-content-center">
                        <strong>{capitalizeWords(column.column)}</strong>
                      </div>

                      <div className="d-flex w-100 align-items-center justify-content-center">
                        {currentField && (
                          <CheckComponent
                            hasData={validation}
                            idx={index}
                            cb={handleButtonClick}
                          />
                        )}
                      </div>
                      <div className="d-flex w-100 align-items-center justify-content-center">
                        <Form.Select
                          id={column.column + "_select"}
                          onChange={(e) =>
                            handleSelectChange(
                              column.column,
                              e.target.value,
                              setDataToSend
                            )
                          }
                          className="w-auto"
                          value={selectedOption}
                        >
                          {selectOptionsComponent(
                            selectedOption,
                            column.column
                          )}
                        </Form.Select>
                      </div>
                    </ListGroup.Item>
                    {!validation && activeKey !== null && (
                      <Accordion
                        activeKey={activeKey}
                        onSelect={(key) => setActiveKey(key)}
                      >
                        <Accordion.Item
                          eventKey={index}
                          style={{ background: "#FAFAFA" }}
                        >
                          <Accordion.Body className="p-0">
                            <Col className="px-0 pt-5 pb-2">
                              <span
                                className="d-flex pb-3 px-3 mx-auto"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                We could not determine the format of your dates
                                and times. Please edit your spreadsheet, or you
                                can import and we will skip these values. Year
                                value must be less than 9999.
                              </span>
                              <hr />
                              <Row className="d-flex align-items-center justify-content-center mx-3 pb-5">
                                {column.values.map((data) => {
                                  const columnData = data || "Invalid Data";
                                  return (
                                    <span
                                      className="d-flex align-items-center justify-content-center m-1"
                                      style={{
                                        minWidth: "125px",
                                        maxWidth: "150px",
                                        padding: "3px 5px",
                                        color: "#D08E62",
                                        background: "#FFFFF0",
                                        border: "0.5px solid #F6E05E",
                                        fontSize: "13px",
                                        borderRadius: "5px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {(columnData || "").toString()}
                                    </span>
                                  );
                                })}
                              </Row>
                            </Col>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )}
                  </>
                );
              })}
              {data.length > 0 &&
                hasCardNameProperty(dataToSend) &&
                !!cardsToCreate.length &&
                cardsToCreate.every(
                  (c) =>
                    typeof c?.name === "string" && c?.name?.trim().length > 0
                ) && (
                  <ListGroup.Item
                    style={{
                      borderRight: "none",
                      borderLeft: "none",
                      borderRadius: "none",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="d-flex w-100"
                    as="li"
                  >
                    <Button
                      className="d-flex"
                      onClick={createCards}
                      style={{
                        background: "#0079bf",
                        border: "none",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disabled={isFetching}
                    >
                      Check and import {fileName}{" "}
                      <MdCloudUpload
                        style={{
                          marginLeft: "5px",
                          fontSize: "25px",
                        }}
                      />
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </>
        )}
      </div>
    </Fade>
  );
};

export default ImportComponent;
