import React, { useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { createClientOnEzDB, createErrorLog } from "../api";
import { getUserInfo } from "../api/trello";
import styles from "./PayPalSubscriptionComponent.module.css";
import {
  FaCalendarAlt,
  FaCalendarCheck,
  FaFrown,
  FaQuestionCircle,
  FaTimesCircle,
} from "react-icons/fa";

import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAndSetAuthStatus,
  fetchAndSetUserInfo,
} from "../redux/authActions";
import { useNavigate } from "react-router-dom";
import { setIsAuthenticated, setPlan } from "../redux/authSlice";
import { Fade } from "react-bootstrap";

const PayPalSubscriptionComponent = () => {
  const { userName, email, isAuthenticated, plan, errorOnAuthRequest } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const monthlyPlanId = "P-7BN42258VT771061RM2FBJSA";
  const anualPlanId = "P-14S792350V6657842M2FBKQY";

  useEffect(() => {
    if (!userName || !email) {
      dispatch(fetchAndSetUserInfo());
    }
    if (email && !isAuthenticated) {
      dispatch(fetchAndSetAuthStatus(email));
    }
  }, [dispatch, email, userName, isAuthenticated]);

  const initialOptions = {
    clientId:
      "Ac84GaEtL961Mh5CnzVrtzb_qO6S4b78ylFyZjz7oEc_-fmO2L2nvFdVbkt-uw1drOYc92bIzVrTFIUj",
    vault: true,
    intent: "subscription",
  };

  const handleGoToImportPage = (selectedPlan) => {
    dispatch(setIsAuthenticated(true));
    dispatch(setPlan(selectedPlan));
    navigate("/imports");
  };

  const createMonthlySubscription = (data, actions) => {
    return actions.subscription.create({ plan_id: monthlyPlanId });
  };

  const createAnualSubscription = (data, actions) => {
    return actions.subscription.create({ plan_id: anualPlanId });
  };

  const onApprove = async (data, period) => {
    const userInfo = await getUserInfo();
    const clientData = {
      name: userInfo.name,
      textEmail: userInfo.email,
      assinatura: [
        {
          name: "EzyImport",
          metodoPagamentoId: 1,
          textIdentificadorMetodoPagamento: data.subscriptionID,
          periodicidadeId: period === "monthly" ? 1 : 2,
        },
      ],
    };

    try {
      await createClientOnEzDB(clientData);
      handleGoToImportPage(period);
    } catch (error) {
      const err = {
        message: error?.message,
        tracer: error,
        email,
        url: error?.url || "onApprove-paypal",
      };

      await createErrorLog(err);
    }
  };

  const onCancel = async (data) => {
  };

  const onError = async (error) => {
    const err = {
      message: error?.message,
      tracer: error,
      email,
      url: error?.url || "onError-paypal",
    };

    await createErrorLog(err);
  };

  return (
    <Fade in appear>
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <div className={`${styles.header} w-100`}>
          <h1>Subscribe to Spread2Board</h1>
        </div>
        <div className={`${styles.description}`}>
          <p className="mb-0">
            <i className="fas fa-info-circle"></i> Once you subscribe to a plan,
            you will be able to use Spread2Board on any Trello board as the plan
            will be associated with your user (email).
          </p>
        </div>
        {errorOnAuthRequest && (
          <h2 className={styles.faq_header}>
            Error retrieving data. Please try again.
          </h2>
        )}
        {!errorOnAuthRequest && (
          <div className={styles.plan_container}>
            {/* Plan sections and PayPal buttons for subscription management */}
            {((!isAuthenticated && !plan) || plan === "monthly") && (
              <div className={styles.plan}>
                {/* Monthly Plan */}
                <h2>
                  <FaCalendarAlt /> <br /> Monthly Plan
                </h2>
                <p>$3 per month</p>
                <div
                  className={styles.paypal_button_container}
                  id="paypal-button-container-monthly"
                >
                  {(!plan || plan === "anual") && (
                    <PayPalScriptProvider options={initialOptions}>
                      <PayPalButtons
                        style={{
                          shape: "rect",
                          color: "blue",
                          layout: "vertical",
                          label: "subscribe",
                        }}
                        createSubscription={createMonthlySubscription}
                        onApprove={(data) => onApprove(data, "monthly")}
                        onCancel={onCancel}
                        onError={onError}
                      />
                    </PayPalScriptProvider>
                  )}
                  {plan === "monthly" && (
                    <span className={styles.span_sub}>
                      Congratulations, you already have a subscription to
                      EzyImport.
                    </span>
                  )}
                </div>
              </div>
            )}
            {((!isAuthenticated && !plan) || plan === "anual") && (
              <div className={styles.plan}>
                {/* Annual Plan */}
                <h2>
                  <FaCalendarCheck /> <br /> Annual Plan
                </h2>
                <p>$30 per year</p>
                <div
                  className={styles.paypal_button_container}
                  id="paypal-button-container-annual"
                >
                  {(!plan || plan === "monthly") && (
                    <PayPalScriptProvider options={initialOptions}>
                      <PayPalButtons
                        style={{
                          shape: "rect",
                          color: "gold",
                          layout: "vertical",
                          label: "subscribe",
                        }}
                        createSubscription={createAnualSubscription}
                        onApprove={(data) => onApprove(data, "anual")}
                        onCancel={onCancel}
                        onError={onError}
                      />
                    </PayPalScriptProvider>
                  )}
                  {plan === "anual" && (
                    <span className={styles.span_sub}>
                      Congratulations, you already have a subscription to
                      EzyImport.
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {/* FAQ Section with trial information */}
        <div className={styles.faq_section}>
          <h2 className={styles.faq_header}>
            <FaQuestionCircle className="mx-2" style={{ color: "#007bff" }} />{" "}
            Frequently Asked Questions
          </h2>
          <Accordion className="w-100 px-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h4 className={styles.faq_item}>
                  <FaFrown className="mx-2" style={{ color: "#ffc107" }} /> What
                  is the trial period and how does it work?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  You will have 15 days to test and make unlimited imports. If
                  you find Spread2Board meets your needs, do nothing, and your
                  subscription will automatically begin after the trial period,
                  charging you for the plan you selected. To avoid charges,
                  please cancel the subscription through PayPal before the 7th
                  day.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h4 className={styles.faq_item}>
                  <FaFrown className="mx-2" style={{ color: "#dc3545" }} /> I'm
                  not happy, can I have my money back?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  We have a 30-day no questions asked money-back guarantee.
                  Simply drop us an email at{" "}
                  <a
                    href="mailto:support.spread2board@smartezy.com.br"
                    style={{ textDecoration: "none", color: "#007bff" }}
                  >
                    support.spread2board@smartezy.com.br
                  </a>
                  , and we'll process your refund swiftly.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h4 className={styles.faq_item}>
                  <FaTimesCircle
                    className="mx-2"
                    style={{ color: "#17a2b8" }}
                  />{" "}
                  How can I cancel my subscription?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  To cancel your subscription, please follow these instructions
                  from PayPal: <br />
                  <br />
                  <strong>1</strong>. Log in to your PayPal account.
                  <br />
                  <strong>2</strong>. Click on "Settings" (gear icon) next to
                  "Log out".
                  <br />
                  <strong>3</strong>. Select "Payments".
                  <br />
                  <strong>4</strong>. Click "Manage automatic payments".
                  <br />
                  <strong>5</strong>. Select the subscription you want to cancel
                  and click "Cancel".
                  <br />
                  <br />
                  For more details, visit{" "}
                  <a
                    href="https://www.paypal.com/us/smarthelp/article/FAQ1067"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "#28a745" }}
                  >
                    PayPal Help
                  </a>
                  .
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className={styles.footer}>
          <p>
            Spread2Board is a product of SmartEzy. For assistance or inquiries,
            please email us at{" "}
            <a href="mailto:support.spread2board@smartezy.com.br">
              support.spread2board@smartezy.com.br
            </a>
            .
          </p>
        </div>
      </div>
    </Fade>
  );
};

export default PayPalSubscriptionComponent;
