import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaFileImport, FaDollarSign, FaBook } from "react-icons/fa";
import styles from "./navbar.module.css";

export function NavBar() {  
  const [isActive, setIsActive] = useState("/imports");
  const navigate = useNavigate();

  const routes = [
    {
      name: "Imports",
      route: "/imports",
      visible: true,
      icon: <FaFileImport className={styles.navLinkIcon} />,
    },
    {
      name: "Billing",
      route: "/billing",
      visible: true,
      icon: <FaDollarSign className={styles.navLinkIcon} />,
    },
    {
      name: "Instructions",
      route: "/instructions",
      visible: true,
      icon: <FaBook className={styles.navLinkIcon} />,
    },
  ];

  const handleGoToPage = (page) => {
    navigate(page);
    setIsActive(page);
  };

  return (
    <nav className={styles.navbar}>
      {routes.map((r) =>
        r.visible ? (
          <div key={r.route} className={styles.navItem}>
            <div
              onClick={() => handleGoToPage(r.route)}
              className={`${styles.navLink} ${
                isActive === r.route ? styles.navLinkActive : ""
              }`}
            >
              {r.icon} {r.name}
            </div>
          </div>
        ) : null
      )}
    </nav>
  );
}

export default NavBar;
