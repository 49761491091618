import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const OverlayLoading = ({ isLoading }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isLoading}
      backdrop="static"
      keyboard={false}
      style={{
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
      }}
      contentClassName="custom-modal-content"
    >
      <Spinner
        animation="grow"
        style={{ margin: "auto", backgroundColor: "#FFF" }}
      />
      <br />
      <br />
      <h5 style={{ color: "#FFF" }}>Loading...</h5>
    </Modal>
  );
};

export default OverlayLoading;
