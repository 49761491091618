import { getUserInfo } from "./trello";

export const createClientOnEzDB = async (data) => {
  const url = "https://ms-ezypowerups-ezyimport.azurewebsites.net/clientes";

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        // console.error("Erro ao enviar os dados:", response.status);
      }
      return response.json();
    })
    .then((result) => {
      // console.log("Resposta:", result);
    })
    .catch((error) => {
      throw new Error("Ocorreu um erro: " + error.message);
    });
};

export const createLayoutOnEzDB = async (data) => {
  const url = "https://ms-ezypowerups-ezyimport.azurewebsites.net/layouts";

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        // console.error("Erro ao enviar os dados:", response.status);
      }
      return response.json();
    })
    .then((result) => {
      // console.log("Resposta:", result);
    });
};

export const getLayout = async (columns, cb) => {
  try {
    const userInfo = await getUserInfo();

    const url = `https://ms-ezypowerups-ezyimport.azurewebsites.net/layouts?txColums=${columns}&email=${userInfo.email}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      // console.error("Erro ao enviar os dados:", response.status);
      return null;
    }

    const result = await response.json();

    if (result.length > 0) {
      return cb(result[0]);
    } else {
      // console.error("Nenhum layout encontrado.");
      return null;
    }
  } catch (error) {
    // console.error("Erro na requisição:", error);
    return null;
  }
};

export const getClientOnEzDB = async (email) => {
  try {
    const url = `https://ms-ezypowerups-ezyimport.azurewebsites.net/clientes?email=${email}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      return null;
    }

    const result = await response.json();

    if (result) {
      return result;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error("Ocorreu um erro: " + error.message);
  }
};

export async function createErrorLog(erro) {
  try {
    const data = {
      "produto": "EzyImport",
      "tx_erro_menssage": erro.message,
      "tx_error_tracer": JSON.stringify(erro.tracer),
      "tx_email_cliente": erro.email,
      "tx_endpoint_error": erro.url,
    };   

    const response = await fetch(process.env.REACT_APP_ERROR_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error response from server: ${errorText}`);
    }
    
  } catch (error) {    
    throw new Error("Error creating error log");
  }
}
