import React from "react";
import { Alert, Button } from "react-bootstrap";
import { FaFile, FaFileExcel } from "react-icons/fa";
import styles from "../pages/instructionsComponent.module.css";
import { useNavigate } from "react-router-dom";

function ImportFileComponent({
  isAuth,
  isInvalidFile,
  handleFileChange,
  handleSelectFile,
  isAuthChecked,
}) {
  const navigate = useNavigate();
  return (
    <>
      <div className={`${styles.header} w-100`}>
        <h1>Select a file to import</h1>
      </div>
      <blockquote className="blockquote mt-3">
        <p>
          Both CSV and XLSX files are supported. You will have a chance to
          review your data before importing.
        </p>
      </blockquote>
      <label
        htmlFor="file-upload"
        className="custom-file-upload"
        style={{
          color: isInvalidFile ? "red" : "initial",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          id="file-upload"
          type="file"
          className="d-none"
          accept=".xlsx,.xls,.csv"
          onChange={handleFileChange}
        />
        {isAuthChecked && isAuth && (
          <Button
            onClick={handleSelectFile}
            size="lg"
            className="mx-2 d-flex"
            style={{
              background: "#0079bf",
              borderColor: "#0079bf",
              alignItems: "center",
            }}
          >
            Choose File{" "}
            {!isInvalidFile && (
              <FaFile style={{ marginLeft: "6px", fontSize: "24px" }} />
            )}
          </Button>
        )}
        {isInvalidFile && "Invalid file. Please check and try again."}
        {isInvalidFile && (
          <FaFileExcel className="mx-2" style={{ fontSize: "22px" }} />
        )}
      </label>

      {!isAuth && isAuthChecked && (
        <Alert color="warning" style={{ marginTop: "5px", width: "700px" }}>
          To begin testing, select a plan under the{" "}
          <u
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => navigate("/billing")}
          >
            Billing
          </u>{" "}
          tab and subscribe. You will have 15 days of unlimited access using the
          email linked to this Trello account. Rest assured, you can cancel your
          subscription at any time during the trial period and will only be
          charged if you do not cancel. We use PayPal to manage the trial
          periods and payments.
        </Alert>
      )}
    </>
  );
}

export default ImportFileComponent;
