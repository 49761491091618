import React from "react";
import { Button, Fade, ProgressBar } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { IoCloudDone } from "react-icons/io5";

const FetchingCardComponent = ({
  progress,
  isCancelled,
  createdCards,
  totalCards,
  stopCreation,
}) => {
  const progressComplet = progress === 100 && !isCancelled;

  return (
    <Fade in appear>
      <div className="w-100 py-5 d-flex flex-column justify-content-center align-items-center bg-light">
        {progressComplet && (
          <>
            <h1>
              import completed successfully
              <IoCloudDone style={{ color: "#4BB543" }} />
            </h1>
            <p>All cards were imported successfully.</p>
          </>
        )}

        {!progressComplet && (
          <>
            <h1>Import Data</h1>
            <p>
              Please keep this window open. Your data import will stop if
              closed.
            </p>
          </>
        )}

        <hr className="w-100" />

        <Button
          className="d-flex my-3"
          onClick={stopCreation}
          style={{
            width: progressComplet ? "190px" : "150px",
            background: progressComplet ? "#4BB543" : "#0079bf",
            border: "none",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {progressComplet ? "Close Window" : "Stop Import"}
          <AiOutlineClose
            style={{
              marginLeft: "5px",
              fontSize: "22px",
            }}
          />
        </Button>
        <ProgressBar
          style={{ width: "250px", height: "25px" }}
          now={progress}
          label={`${progress}%`}
        />
        <span className="mt-3">
          Card{createdCards !== 1 && "s"} {createdCards} / {totalCards}
        </span>
      </div>
    </Fade>
  );
};

export default FetchingCardComponent;
