import React, { useEffect } from "react";
import styles from "./instructionsComponent.module.css";
import { FaDownload, FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAndSetAuthStatus,
  fetchAndSetUserInfo,
} from "../redux/authActions";

function InstructionsComponent() {
  const { userName, email, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userName || !email) {
      dispatch(fetchAndSetUserInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (email && !isAuthenticated) {
      dispatch(fetchAndSetAuthStatus(email));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, email]);

  const colors = [
    { name: "Red", style: styles.red },
    { name: "Orange", style: styles.orange },
    { name: "Yellow", style: styles.yellow },
    { name: "Green", style: styles.green },
    { name: "Blue", style: styles.blue },
    { name: "Purple", style: styles.purple },
    { name: "Pink", style: styles.pink },
    { name: "Black", style: styles.black },
    { name: "Grey", style: styles.grey },
    { name: "Lime", style: styles.lime },
    { name: "Sky", style: styles.sky },
  ];

  const colorGroups = [];
  for (let i = 0; i < colors.length; i += 4) {
    colorGroups.push(colors.slice(i, i + 4));
  }

  return (
    <div>
      <div>
        <div className={`${styles.header} w-100`}>
          <h1>Spread2Board Instructions</h1>
        </div>
        <div className="container">
          <h2 className={styles.h2}>Simple and Powerful Import</h2>
          <p className={styles.p}>
            With Spread2Board, each column of your spreadsheet transforms into a
            key part of your Trello board. Just select how you want to import
            each column, and you're done!
          </p>
          <br />          
          <div style={{ textAlign: "center" }}>
            <img src="/img/demo1.gif" style={{ width: "100%" }} alt="Demo" />
          </div>

          <br />
          <br />

          <h2 className={styles.h2}>
            Versatile Import of CSV, Excel, and Google Sheets
          </h2>
          <p className={styles.p}>
            Import Excel files saved as XLSX directly. For Google Sheets, save
            as CSV and import without complications. The first line of your
            spreadsheet will be used as the header, helping map your data to
            Trello accurately.
          </p>
          <br />
          <h2 className={styles.h2}>Examples and Flexibility</h2>
          <p className={styles.p}>
            See a simple spreadsheet example or use any export created in
            Trello, which can be imported using Spread2Board. We map all these
            fields perfectly:
          </p>
          <ul className={styles.ul}>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>name, title, or card name:</strong> Mapped to the card name
            </li>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>description, card description, or desc:</strong> Mapped to
              the card description
            </li>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>list:</strong> The name of the list where the card
              should be imported
            </li>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>members:</strong> The names of the members assigned to the
              card
            </li>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>labels:</strong> Names and/or colors of the labels added
              to the card
            </li>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>due date:</strong> Mapped to the card's due date
            </li>
          </ul>
          <br />
          <h2 className={styles.h2}>Detailed Checklists</h2>
          <ul className={styles.ul}>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>Checklist:</strong> Name of the Checklist
            </li>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>Checklist Item:</strong> Name of the checklist item
            </li>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>Checklist Item Due Date:</strong> Due date of the item
            </li>
            <li className={styles.li}>
              <FaCheckCircle className="mx-2" />
              <strong>Checklist Item Member:</strong> Member assigned to the
              item
            </li>
          </ul>
          <br />          
          <div style={{ textAlign: "center" }}>
            <img src="/img/demo3.gif" style={{ width: "100%" }} alt="Demo" />
          </div>
          <br />
          <h2 className={styles.h2}>Adding Multiple Labels</h2>
          <p className={styles.p}>
            You can add multiple labels to your card by separating the colors
            with commas. These are the available colors:
          </p>
          <table className={styles.colorTable}>
            <tbody>
              {colorGroups.map((group, index) => (
                <tr key={index}>
                  {group.map((color, subIndex) => (
                    <React.Fragment key={subIndex}>
                      <td className={styles.colorCell}>
                        <div
                          className={`${styles.colorButton} ${color.style}`}
                          style={{ display: "inline-block", verticalAlign: "middle" }}
                        />
                        <span
                          className={styles.colorName}
                          style={{ marginLeft: "8px", verticalAlign: "middle" }}
                        >
                          {color.name}
                        </span>
                      </td>
                    </React.Fragment>
                  ))}
                  {group.length < 4 &&
                    Array.from({ length: 4 - group.length }).map((_, subIndex) => (
                      <td key={subIndex} className={styles.colorCell}></td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <h2 className={styles.h2}>Adding Multiple Members</h2>
          <p className={styles.p}>
            You can add multiple members to your card by using emails or member
            usernames, separated by commas.
          </p>
          <br />
          <h2 className={styles.h2}>Incompatible Data? No Problem!</h2>
          <p className={styles.p}>
            If we find data that can't be imported, we'll show you exactly where
            the problem is. Expand the sections to get more details and easily
            resolve them: create the corresponding List, Label, or Custom Field
            in Trello, or adjust your spreadsheet so everything fits perfectly.
            You can also choose to ignore these issues and import your
            spreadsheet without this data.
          </p>
          <br />          
          <div style={{ textAlign: "center" }}>
            <img src="/img/demo2.gif" style={{ width: "100%" }} alt="Demo" />
          </div>
          <br />
          <h2 className={styles.h2}>Save Your Layouts</h2>
          <p className={styles.p}>
            You can save layouts of previously imported spreadsheets, making
            future imports even faster and more efficient. For each spreadsheet
            import, compatible layouts (previously imported spreadsheets with
            the same columns) will be checked, and we'll use previous imports as
            a model for the current import, making your work faster.
          </p>
          <br />
          <a href="/download/Template.xlsx" className={styles.btnDownload}>
            <FaDownload className="mx-2" /> Click here to download a spreadsheet
            template
          </a>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

export default InstructionsComponent;