import { isValidColor } from "../utils";

function GetSecret() {
  const t = window.TrelloPowerUp.iframe({
    appKey: process.env.REACT_APP_TRELLO_KEY,
    appName: "Ezy Spread2Board by Ezy PowerUps",
  });

  return new Promise((resolve, reject) => {
    t.getRestApi()
      .getToken()
      .then(function (secret) {
        let isSetToken = decodeURI(secret).replace('"', "").replace('"', "");
        resolve(isSetToken);
      })
      .catch(function () {
        resolve(null);
      });
  });
}

export const getUserInfo = async () => {
  return GetSecret().then((secret) => {
    const token = secret;
    const url = `https://api.trello.com/1/members/me?key=${process.env.REACT_APP_TRELLO_KEY}&token=${token}`;
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return {
          name: data.fullName,
          email: data.email,
        };
      })
      .catch((error) => {
        error.url = url;
        throw error;
      });
  });
};

export async function createChecklist(cardId, checklistName, token) {
  const checklistData = {
    name: checklistName,
    idCard: cardId,
  };

  const url = `https://api.trello.com/1/checklists?key=${process.env.REACT_APP_TRELLO_KEY}&token=${token}`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(checklistData),
    });
    const createdChecklist = await response.json();
    return createdChecklist;
  } catch (error) {
    error.url = url;
    throw error;
  }
}

export async function createChecklistItem(
  checklistId,
  checklistItemData,
  token
) {
  const url = `https://api.trello.com/1/checklists/${checklistId}/checkItems?key=${process.env.REACT_APP_TRELLO_KEY}&token=${token}`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(checklistItemData),
    });
    const createdChecklistItem = await response.json();
    return createdChecklistItem;
  } catch (error) {
    error.url = url;
    throw error;
  }
}

export async function createLabel(colors, idBoard, token, t) {
  const APIKey = process.env.REACT_APP_TRELLO_KEY;
  const url = `https://api.trello.com/1/labels?key=${APIKey}&token=${token}`;

  const colorsArray = colors?.trim().split(",");
  const labelIds = [];
  try {
    for (const _color of colorsArray) {
      let color = _color?.trim();

      if (!isValidColor(color)) {
        t.alert({
          message: "Invalid color provided. " + color,
        });
        throw new Error("Invalid color provided. " + color);
      }

      const labelId = localStorage.getItem(color);

      if (labelId) {
        labelIds.push(labelId);
      } else {
        const body = {
          name: color,
          color: color.toLowerCase(),
          idBoard: idBoard,
        };

        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          });

          if (!response.ok) {
            throw new Error(
              `Error creating label for color "${color}": ${response.statusText}`
            );
          }

          const createdLabel = await response.json();

          localStorage.setItem(color, createdLabel.id);

          labelIds.push(createdLabel.id);
        } catch (error) {
          throw error;
        }
      }
    }

    return labelIds;
  } catch (error) {
    error.url = url;
    throw error;
  }
}

export async function getMemberId(emailsOrLogins, token) {
  const APIKey = process.env.REACT_APP_TRELLO_KEY;
  const urlBase = "https://api.trello.com/1/members/";

  const emailOrLoginsArray = emailsOrLogins?.toString()?.trim().split(",");
  const memberIds = [];

  for (const _emailOrLogin of emailOrLoginsArray) {
    const emailOrLogin = _emailOrLogin?.trim();
    const url = `${urlBase}${emailOrLogin}?key=${APIKey}&token=${token}`;

    try {
      const storedMemberId = localStorage.getItem(emailOrLogin);
      if (storedMemberId) {
        memberIds.push(storedMemberId);
      } else {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(
            `Error fetching member ID for "${emailOrLogin}": ${response.statusText}`
          );
        }

        const memberData = await response.json();
        const memberId = memberData.id;

        localStorage.setItem(emailOrLogin, memberId);

        memberIds.push(memberId);
      }
    } catch (error) {
      error.url = url;   
      throw error;
    }
  }

  return memberIds;
}

export async function createList(listName, boardId, token) {
  const url = `https://api.trello.com/1/lists?key=${process.env.REACT_APP_TRELLO_KEY}&token=${token}`;
  const listData = {
    name: listName,
    idBoard: boardId,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(listData),
    });

    if (!response.ok) {
      throw new Error(`Erro ao criar lista "${listName}": ${response.statusText}`);
    }

    const createdList = await response.json();
    return createdList;
  } catch (error) {
    error.url = url;
    throw error;
  }
}
