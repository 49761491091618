import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import ImportComponent from "./pages/ImportComponent";
import BillingComponent from "./pages/PayPalSubscriptionComponent";
import OverlayLoading from "./components/OverlayLoading";
import { useSelector } from "react-redux";
import Layout from "./components/Layout";
import InstructionsComponent from "./pages/InstructionsComponent";
import SignedMenu from "./components/TrelloMenu/Signed";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        path: "/",
        element: (
          <div className="p-5 text-center">
            <h3>Hello World.</h3>
          </div>
        ),
      },
      { path: "/imports", element: <ImportComponent /> },
      { path: "/billing", element: <BillingComponent /> },
      { path: "/instructions", element: <InstructionsComponent /> },
      { path: "/menu", element: <SignedMenu /> },
      {
        path: "*",
        element: (
          <div className="p-5 text-center">
            <h2>404</h2>
            <h3>Página desconhecida.</h3>
          </div>
        ),
      },
    ],
  },
]);

function App() {
  const { loading } = useSelector((state) => state.auth);  
  useEffect(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const lastCleanDate = localStorage.getItem("lastCleanDate");
    const formatDate = (date) => {
      return date.toISOString().split("T")[0];
    };

    if (dayOfWeek === 1 && formatDate(today) !== lastCleanDate) {
      const colors = [
        "yellow",
        "purple",
        "blue",
        "red",
        "green",
        "orange",
        "black",
        "sky",
        "pink",
        "lime",
      ];

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (
          colors.some(
            (color) => key && key.toLowerCase() === color.toLowerCase()
          )
        ) {
          localStorage.removeItem(key);
        }
      }

      localStorage.setItem("lastCleanDate", formatDate(today));
    }
  }, []);

  return (
    <>
      <RouterProvider router={router} />
      {loading && <OverlayLoading isLoading={loading} />}
    </>
  );
}

export default App;
